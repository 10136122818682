<template>
  <div>
    <section class="section is-main-section">
      <h1 class="title">
        {{ title }}
        <b-button
          class="is-success is-pulled-right"
          @click="add()"
          icon-left="plus"
        >
          ເພີ່ມໃໝ່
        </b-button>
      </h1>
      <b-table
        :loading="isLoading"
        :paginated="perPage < models.length"
        :per-page="perPage"
        :striped="true"
        :hoverable="true"
        default-sort="name"
        :data="models"
        :current-page="currentPage"
        :pagination-rounded="true"
      >
        <b-table-column label="#" v-slot="props">
          {{ props.index * currentPage + 1 }}
        </b-table-column>

        <b-table-column label="ອ້າງອີງ" sortable v-slot="props">
          {{ props.row.ref }}
        </b-table-column>
        <b-table-column label="ວິທີສົ່ງ" sortable v-slot="props">
          {{ props.row.method }}
        </b-table-column>
        <b-table-column label="ເສ້ນທາງ" sortable v-slot="props">
          {{ props.row.branch_branchToprice_from_branch_id.code }}
          {{ props.row.branch_branchToprice_from_branch_id.name }}
          ~
          {{ props.row.branch_branchToprice_to_branch_id.code }}
          {{ props.row.branch_branchToprice_to_branch_id.name }}
        </b-table-column>
        <b-table-column label="ຂະໜາດ" sortable v-slot="props">
          {{ props.row.min_size | formatnumber }} ~
          {{ props.row.max_size | formatnumber }}
        </b-table-column>
        <b-table-column label="ນໍ້າໜັກ" sortable v-slot="props">
          {{ props.row.min_weight | formatnumber }}
          ~
          {{ props.row.max_weight | formatnumber }}
        </b-table-column>
        <b-table-column label="ລາຄາ" sortable v-slot="props">
          {{ props.row.fee | formatnumber }}
        </b-table-column>
        <b-table-column
          custom-key="actions"
          cell-class="is-actions-cell"
          v-slot="props"
        >
          <div class="buttons is-right">
            <b-button
              class="is-small is-warning"
              @click="edit(props.row)"
              icon-left="pencil"
            >
            </b-button>
            <b-button
              class="is-small is-danger"
              @click="remove(props.row)"
              icon-left="trash-can"
            >
            </b-button>
          </div>
        </b-table-column>

        <empty-table v-if="models.length == 0" :isLoading="isLoading" />
      </b-table>
    </section>

    <div class="modal is-active is-large" v-if="isShowModal">
      <div class="modal-background"></div>
      <div class="modal-card" v-if="model">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ title }}</p>
          <button
            class="delete"
            aria-label="close"
            @click="isShowModal = false"
          ></button>
        </header>
        <section class="modal-card-body">
          <b-field label="ປະເພດເຄື່ອງ" horizontal>
            <b-select
              v-model="model.parcel_type"
              placeholder="ປະເພດເຄື່ອງຝາກ"
              required
            >
              <option
                v-for="(type, index) in parceltypes"
                :key="index"
                :value="type"
              >
                {{ type.name }}
              </option>
            </b-select>
          </b-field>
          <b-field label="ຂະໜາດ" horizontal>
            <b-slider
              tooltip-always
              size="is-medium"
              v-model="model.min_size"
              placeholder="ຕໍ່າສຸດ"
              required
            />
            <b-slider
              tooltip-always
              size="is-medium"
              v-model="model.max_size"
              placeholder="ຫຼາຍສຸດ"
              required
            />
          </b-field>
          <b-field label="ນໍ້າໜັກ" horizontal>
            <b-slider
              tooltip-always
              size="is-medium"
              v-model="model.min_weight"
              placeholder="ຕໍ່າສຸດ"
              required
            />
            <b-slider
              tooltip-always
              size="is-medium"
              v-model="model.max_weight"
              placeholder="ຫຼາຍສຸດ"
              required
            />
          </b-field>
          <b-field label="ຈາກສາຂາ" horizontal>
            <b-select
              v-model="model.from_branch"
              placeholder="ຈາກສາຂາ"
              required
            >
              <option
                v-for="(type, index) in frombranches"
                :key="index"
                :value="type"
              >
                {{ type.code }} {{ type.name }}
              </option>
            </b-select>
            <b-select v-model="model.to_branch" placeholder="ຫາສາຂາ" required>
              <option
                v-for="(type, index) in tobranches"
                :key="index"
                :value="type"
              >
                {{ type.code }} {{ type.name }}
              </option>
            </b-select>
          </b-field>
          <b-field label="ວິທີສົ່ງ" horizontal>
            <b-select v-model="method" placeholder="ວິທີສົ່ງ" required>
              <option
                v-for="(type, index) in [
                  { code: 'express', name: 'ດ່ວນ' },
                  { code: 'normal', name: 'ທຳມະດາ' },
                ]"
                :key="index"
                :value="type"
              >
                {{ type.name }} ({{ type.code }})
              </option>
            </b-select>
          </b-field>
          <!-- <b-field label="ອ້ງອີງ" horizontal>
            <b-input v-model="model.ref" placeholder="ອ້ງອີງ" required />
          </b-field> -->
          <b-field label="ລາຄາ" horizontal>
            <b-input
              v-model="model.fee"
              placeholder="ລາຄາ"
              required
              type="number"
            />
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <b-button
            class="is-success"
            slot="right"
            @click="save()"
            icon-left="check"
          >
            ບັນທຶກ
          </b-button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import { get } from "vuex-pathify";
import EmptyTable from "../components/EmptyTable.vue";
export default {
  name: "Prices",
  components: { EmptyTable },
  computed: {
    user: get("user"),
    parceltypes: get("parceltypes"),
    frombranches: get("branches"),
    tobranches: get("branches"),
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      title: "ລາຄາຂົນສົ່ງ",
      model: null,
      models: [],
      isShowModal: false,
      isLoading: false,
      method: null,
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      this.isLoading = true;
      Axios.get("prices/gets")
        .then((r) => {
          this.isLoading = false;
          this.models = r;
        })
        .catch((e) => {
          this.isLoading = false;
          console.error(e);
        });
    },
    add() {
      this.model = {};
      this.isShowModal = true;
    },
    edit(item) {
      this.model = item;
      this.model.from_branch = item.branch_branchToprice_from_branch_id;
      this.model.to_branch = item.branch_branchToprice_to_branch_id;
      this.model.parcel_type = item.parceltype;
      this.isShowModal = true;
    },
    remove(item) {
      this.$buefy.dialog.confirm({
        title: "ຢືນຢັນການລຶບຂ້ໍມູນ",
        message: "ທ່ານຕ້ອງການ <b>ລຶບ</b> ແທ້ບໍ?",
        confirmText: "ແມ່ນ, ລຶບ",
        cancelText: "ບໍ່",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          Axios.delete(`prices/${item.id}`).then((r) => {
            console.log(r);
            this.get();
          });
        },
      });
    },
    save() {
      let req;
      this.model.from_branch_id = this.model.from_branch.id;
      this.model.to_branch_id = this.model.to_branch.id;
      this.model.parcel_type_id = this.model.parcel_type.id;
      this.model.method = this.method.code;
      if (this.model.id) {
        req = Axios.put("prices", this.model);
      } else {
        req = Axios.post("prices", this.model);
      }
      req.then((res) => {
        console.log(res);
        this.closeModal();
        this.get();
      });
    },
    closeModal() {
      this.isShowModal = false;
    },
  },
};
</script>
